@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,500;0,700;1,400&display=swap');

*, *:after, *:before {
    box-sizing: border-box;
    font: inherit;
    color: inherit;
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
}
  
body {
  font: 200 14px Roboto, sans-serif;
	line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  /* overflow-y: scroll !important */
}

textarea {
  width:100%;
  resize:none;
  font-size: 14px;
  border-style: solid;
  border: 1px solid black;
}

.error-helper-text {
  color:"red";
  font-size: 9px;
}

.main-container {
  position: absolute;
  top: 64px; 
  left: 0px; 
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.video-container {
  position: absolute;
  top: 64px; 
  left: 0px; 
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.video-container video {
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
  z-index: 1;
}

.video-container:after {
  content: '';
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.55);
  position: absolute;
  z-index: 1;
}

.video-section-info {
  position: absolute;
  color:rgb(255, 255, 255);
  z-index:2;
}

.image-container {
  position: absolute;
  top: 56px; 
  left: 0px; 
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.image-container img {
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
  z-index: 2;
}
.image-container:after {
  content: '';
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.35);
  position: absolute;
  z-index: 2;
}
.section-description {
  position: relative;
  color:rgb(255, 255, 255);
  font-size: 14px;
  z-index:2;
}
.image-ss-container {
  position: absolute;
  top: 56px; 
  left: 0px; 
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.image-ss-container img {
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
  z-index: 1;
}
.image-ss-container:after {
  content: '';
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.55);
  position: absolute;
  z-index: 1;
}

